<template>
	<div class="resolucionFormHeader puiformheader">
		<v-row>
			<v-col cols="12">
				<pui-field :label="$t('header.resolucion.numdoc')" :value="getNumdocValue"></pui-field>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12">
				<pui-field :label="$t('header.resolucion.tipres')" :value="getTipresValue"></pui-field>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12">
				<pui-field :label="$t('header.resolucion.areaopera')" :value="getAreaoperaValue"></pui-field>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import PuiFormHeaderMixin from 'pui9-mixins/PuiFormHeaderMixin';

export default {
	name: 'resolucion-form-header',
	mixins: [PuiFormHeaderMixin],
	data() {
		return {
			modelName: 'resolucion'
		};
	},
	computed: {
		getNumdocValue() {
			return this.model && this.model.numdoc ? this.model.numdoc : '-';
		},
		getTipresValue() {
			return this.model && this.model.tipres ? this.model.tipres : '-';
		},
		getAreaoperaValue() {
			return this.model && this.model.areaopera ? this.model.areaopera : '-';
		}
	}
}
</script>
